import React from 'react'
import './DeleteIcon.scss'

const DeleteIcon = () => {
  return (
    <svg className="delete-icon" viewBox="0 0 100 100">
      <g>
        <line id="svg_6" y2="97.600471" x2="19.333409" y1="32.37531" x1="19.333409" />
        <line id="svg_3" y2="97.552607" x2="80.763657" y1="21.689163" x1="80.763657" />
        <line id="svg_4" y2="157.481618" x2="47.058824" y1="157.775735" x1="47.352941" />
        <line id="svg_5" y2="95.782291" x2="82.699682" y1="95.782291" x1="17.300317" />
        <line id="svg_7" y2="20.346938" x2="94.792117" y1="20.346938" x1="5.207882" />
        <line id="svg_8" y2="43.842881" x2="-31.757083" y1="44.248288" x1="-32.16249" />
        <line id="svg_9" y2="83.200845" x2="40.122577" y1="36.428084" x1="40.122577" />
        <line id="svg_10" y2="83.200845" x2="61.379302" y1="36.428084" x1="61.379302" />
        <line id="svg_14" y2="5.705615" x2="62.973026" y1="5.705615" x1="37.026975" />
        <line id="svg_16" y2="19.596684" x2="76.035488" y1="5.15659" x1="61.595394" />
        <line id="svg_17" y2="19.802321" x2="23.747171" y1="5.148623" x1="38.40087" />
      </g>
    </svg>
  )
}

export default DeleteIcon
